import React from 'react';
import {useNavigate} from "react-router-dom";

import axios from "axios";
import adapter from 'webrtc-adapter';
import {MdVolumeUp, MdVolumeOff} from "react-icons/md";

import {URLORIGIN} from '../../config.js';
import alertAudio from "../../assets/alert.wav";
import './security.scss';

export default function Security(){
    const [
        clientStartDateTime, 
        setClientStartDateTime
    ] = React.useState(new Date());
    const [
        serverStartDateTime, 
        setServerStartDateTime
    ] = React.useState(new Date());
    const [
        serverCurrentDateTime, 
        setServerCurrentDateTime
    ] = React.useState(new Date());
    const [
        alertOffTime,
        setAlertOffTime
    ] = React.useState(new Date(0));
    const [
        alertOnTime,
        setAlertOnTime
    ] = React.useState(new Date(0));
    const [
        alertOn, 
        setAlertOn
    ] = React.useState(false);
    const [
        alertSoundPermission,
        setAlertSoundPermission
    ] = React.useState(false);
    const [
        yoloServerConnection, 
        setYoloServerConnection
    ] = React.useState(null);
    const [
        webrtcPeerConnection, 
        setWebRTCPeerConnection
    ] = React.useState(null);
    const [
        leavingPage,
        setLeavingPage
    ] = React.useState(false);
    const yoloServerAddress = "wss://hkferry.info:32768";
    const videoEndHour = 14;
    const videoStartHour = 16;
    const navigate = useNavigate();
    // TODO: Add time-triggered event to open new websocket connection and the subsequent webrtc connection upon 17:00 and vice versa at 14:00.

    const keyPressAction = keyPressed => {
        if (keyPressed.key === 'm'){
            if (alertSoundPermission){
                setAlertOn(false);
            }
            setAlertSoundPermission(!alertSoundPermission);
        }
        if (keyPressed.key === " "){
            if (alertOn){
                setAlertOn(false);
                setAlertOffTime(new Date());
            }
        }
    }

    const startNewStream = () => {
        
        var newYoloServerConnection = new WebSocket(yoloServerAddress);

        var newWebRTCPeerConnection = new RTCPeerConnection(
            {
                bundlePolicy: "max-compat",
                iceCandidatePoolSize: 30,
                iceServers: [     // Information about ICE servers - Use your own!
                {
                    //urls: "turn:113.28.228.162:442",
		    urls: "turn:113.28.228.163:32769",
		    username: "IChzwQkYZMy",
                    credential: "lnzGjvKxXHk"
                },
                {
                    //urls: "stun:113.28.228.162:442"
		    urls: "stun:113.28.228.163:32769"
                }
                ],
                //iceTransportPolicy: "all",
		iceTransportPolicy: "relay",
                rtcpMuxPolicy: "negotiate"
            }
        );
        newWebRTCPeerConnection.ontrack = newTrackReception => {
            console.log("New track received.");
            console.log(document.getElementById(
                "human-stream-video"
            ));
            console.log(newTrackReception);
            console.log(newTrackReception.streams);
            console.log(newTrackReception.streams[0]);
            var stream = newTrackReception.streams[0];
            stream.onaddtrack = trackAdded=>{
                    console.log("Track added.");
                    console.log(trackAdded);
                }
            document.getElementById(
                "human-stream-video"
            ).srcObject = newTrackReception.streams[0];
            document.getElementById(
                "human-stream-video"
            ).defaultPlaybackRate = 1.1;
            stream.onremovetrack = trackRemoved=>{
                console.log("Track removed.");
                console.log(trackRemoved);
            }
            stream.onactive = videoActivation=>{
                console.log("Stream activated.");
                console.log(videoActivation);
            }
            stream.oninactive = videoDeactivation=>{
                console.log("Stream deactivated.");
                console.log(videoDeactivation);
            }
            document.getElementById(
                "human-stream-video"
            ).srcObject = stream;
            console.log( document.getElementById(
                "human-stream-video"
            ).srcObject);
            console.log("New track added.")
        };
        newWebRTCPeerConnection.onremovetrack = () => {
            console.log("track removed.");
            const trackList = document.getElementById(
                "human-stream-video"
            ).srcObject.getTracks();
          
            if (trackList.length == 0) {
              handleStreamClosing(newWebRTCPeerConnection, newYoloServerConnection);
            }
        };
        newWebRTCPeerConnection.oniceconnectionstatechange = () => {
            switch(newWebRTCPeerConnection.iceConnectionState){
                case "disconnected":
                    console.log("ICE connection closed.");
                    handleStreamClosing(
                        newWebRTCPeerConnection, newYoloServerConnection
                    );
                    break;
                case "closed": 
                    console.log("ICE connection closed.");
                    handleStreamClosing(
                        newWebRTCPeerConnection, newYoloServerConnection
                    );
                    break;
                case "failed":
                    console.log("ICE connection failed.");
                    handleStreamClosing(
                        newWebRTCPeerConnection, newYoloServerConnection
                    );
                    break;
            }
        }
        newWebRTCPeerConnection.onsignalingstatechange = () => {
            switch(newWebRTCPeerConnection.signalingState){
                case "closed":
                    handleStreamClosing(
                        newWebRTCPeerConnection, newYoloServerConnection
                    );
                    break;
            }
        }

        newWebRTCPeerConnection.onicecandidate = newICECandidateFound => {
            if (newICECandidateFound.candidate){
                if (newICECandidateFound.candidate.protocol !== "tcp"){
                    // console.log(newICECandidateFound.candidate);
                    // if (newICECandidateFound.candidate.address.substring(newICECandidateFound.candidate.address.length - 6) != ".local"){
                        // console.log(newICECandidateFound.candidate.address.substring(newICECandidateFound.candidate.address.length - 6))
                        // console.log("New ICE Candidate found");
                        // console.log(newICECandidateFound);
                    newYoloServerConnection.send(
                        JSON.stringify(
                            {
                                type: "new-ice-candidate",
                                target: "server",
                                candidate: newICECandidateFound.candidate
                            }
                        )
                    );
                    // console.log(newWebRTCPeerConnection.getReceivers());
                    newWebRTCPeerConnection.getReceivers()[0].playoutDelayHint = 0;
                    // }
                }
            }
        };
        newWebRTCPeerConnection.onicecandidateerror = ICECandidateReturnedError => console.log(ICECandidateReturnedError);
        newYoloServerConnection.onerror = yoloServerConnectionErrorEvent => {
            console.log('WebSocket error: ', yoloServerConnectionErrorEvent);
            handleStreamClosing(webrtcPeerConnection, newYoloServerConnection);
        }
        newYoloServerConnection.onmessage = yoloServerMessageReception => {
            const yoloServerMessage = JSON.parse(yoloServerMessageReception.data);
            switch(yoloServerMessage.type){
                case "video-offer":
                    console.log("received video offer");
                    console.log(yoloServerMessage);
                    newWebRTCPeerConnection.setRemoteDescription(
                        yoloServerMessage.sdp
                    ).then(
                        () => newWebRTCPeerConnection.createAnswer()
                    ).then(
                        answer => {
                            console.log("answer");
                            console.log(answer);
                            return newWebRTCPeerConnection.setLocalDescription(
                                answer
                            );
                        }
                    ).then(
                        () => newYoloServerConnection.send(
                            JSON.stringify(
                                {
                                    name:yoloServerMessage.target,
                                    target: yoloServerMessage.name,
                                    type: "answer",
                                    sdp: newWebRTCPeerConnection.localDescription
                                }
                            )
                        )
                    );
                    console.log("Finished setting remote description.");
                    break;
                case "new-ice-candidate":
                    if (yoloServerMessage.candidate.protocol !== "tcp"){
                        const newIceCandidate = new RTCIceCandidate(yoloServerMessage.candidate);
                        newWebRTCPeerConnection.addIceCandidate(
                            newIceCandidate
                        ).catch(
                            addIceCandidateFailedError => {
                                console.log("Add ice candidate failed.");
                                // console.log(addIceCandidateFailedError);
                            }
                        );
                    }
                    // console.log("Ice candidate received.");
                    // console.log(newIceCandidate);
                    break;
            }
        }
        setLeavingPage(false);
        if (newWebRTCPeerConnection)console.log("webrtc peer connection exists.");
        setWebRTCPeerConnection(newWebRTCPeerConnection);
        if (newYoloServerConnection)console.log("soup connection exists");
        return newYoloServerConnection;
    }

    const handleStreamClosing = async (
        inputWebrtcPeerConnection, 
        inputYoloServerConnection, 
        isUnmount=false
    ) => {
        var currentWebrtcPeerConnection;
        if (inputWebrtcPeerConnection) {
            currentWebrtcPeerConnection = inputWebrtcPeerConnection;
        } else if (webrtcPeerConnection) currentWebrtcPeerConnection = webrtcPeerConnection;
        console.log(currentWebrtcPeerConnection);
        if (currentWebrtcPeerConnection){
            currentWebrtcPeerConnection.ontrack=null;
            currentWebrtcPeerConnection.onremovetrack=null;
            currentWebrtcPeerConnection.onremovestream=null;
            currentWebrtcPeerConnection.onicecandidate=null;
            currentWebrtcPeerConnection.oniceconnectionstatechange=null;
            currentWebrtcPeerConnection.onsignalingstatechange=null;
            currentWebrtcPeerConnection.onicegatheringstatechange=null;
            currentWebrtcPeerConnection.onnegotiationneeded=null;
            if (
                document.getElementById(
                    "human-stream-video"
                )
            ) if (
                document.getElementById(
                    "human-stream-video"
                ).srcObject
            ) document.getElementById(
                "human-stream-video"
            ).srcObject.getTracks().forEach(
                track => track.stop()
            );
            currentWebrtcPeerConnection.close();
            console.log("webrtc connection closing.");
            currentWebrtcPeerConnection = null;
            setWebRTCPeerConnection(null);
        }
        console.log("soup connection closing.");
        if (inputYoloServerConnection) {
            console.log(inputYoloServerConnection);
            inputYoloServerConnection.close();
        } else if (yoloServerConnection) {console.log(yoloServerConnection);yoloServerConnection.close();}
        if (!isUnmount){
            new Promise(() => setTimeout(startNewStream, 10000)).then(
                nextYoloServerConnection => setYoloServerConnection(
                    nextYoloServerConnection
                )
            );
        } else setYoloServerConnection(null);
    }
    // TODO: re-establish connection on server disconnect.
    React.useEffect(
        ()=>{
            document.getElementById("alert-audio").onplay = () => console.log("Audio is playing.");
            // Send authentication query to backend only upon first loading.
            if (!webrtcPeerConnection && !yoloServerConnection && !leavingPage) axios.get(
                `${URLORIGIN}/security/init`, {
                    baseURL: "",
                    withCredentials: true}
            ).then(
                response => {
                    // At first rendering, send a query to the server to get the current server time down to the milisecond, 
                    // translate that to the client device's timezone then start the setinterval ticking.
                    // convert the current time to this format at the backend: "2011-10-10T14:48:00.000+09:00", then parse with date.parse.
                    // Use this formula: (Current client datetime - Starting client datetime + Starting server datetime) -> client timezone
                    var serverTime = new Date(Date.parse(response.data.current_time));
                    setServerStartDateTime(serverTime);
                    setClientStartDateTime(new Date());
                    setYoloServerConnection(startNewStream());
                }
            ).catch(
                error => {
                    if (error.response.status === 303) {
                        navigate(error.response.data.url)
                    }
                }
            );
            // TODO: Check if this is a time when the stream is available before opening a new socket, if not than open a blank rectangle with text: the security stream will be available from 00:00–13:59 and 17:00–23:59.
            return () => {
                if (webrtcPeerConnection && yoloServerConnection && leavingPage){
                    console.log("unmounting componenets.");
                    handleStreamClosing(webrtcPeerConnection, yoloServerConnection, true);
                }
                setLeavingPage(true);
            }
        }, [webrtcPeerConnection, yoloServerConnection, leavingPage]
    );
    //  Query a binary response every 2 seconds on humans detected. 
    // Only send an alert to frontend if there are humans detected for more than 80% frames in 2.5 second. 
    // Only rerender the frontend upon status changes (off -> on & on -> off)
    React.useEffect(
        () => {
            document.addEventListener(
                "keydown",
                keyPressAction
            );
            const perSecondFunction = setInterval(
                () => {
                    const serverCurrentDateTimeTemp = new Date(
                        new Date().getTime() - clientStartDateTime.getTime() + serverStartDateTime.getTime()
                    );
                    setServerCurrentDateTime(
                        serverCurrentDateTimeTemp
                    );
                    if (!alertOn) {
                        axios.get(
                            `${URLORIGIN}/security/alert`,{
                                baseURL: "",
                                withCredentials: true
                            }
                        ).then(
                            response => {
                                if (response.data.human_is_detected){
                                    if ((new Date() - alertOffTime) > 60000){
                                        setAlertOn(true);
                                        setAlertOnTime(new Date());
                                    }
                                }
                            }
                        )
                    } else if ((new Date() - alertOnTime) > 30000) setAlertOn(false);
                }, 1000
            );
            if (alertOn && alertSoundPermission){
                document.getElementById("alert-audio").play();
            } else document.getElementById("alert-audio").pause();
            return () => {
                clearInterval(perSecondFunction);
                document.removeEventListener(
                    "keydown",
                    keyPressAction
                );
            }
        }, [alertOn, alertSoundPermission, alertOnTime, alertOffTime,  yoloServerConnection]
    )
    return(
        <section
        id="security-alert" className={alertOn?"security-alert":"security-no-alert"}>
            {
                (
                    serverCurrentDateTime.getHours() >= videoEndHour 
                    && serverCurrentDateTime <= videoStartHour
                )
                    ?<input
                        id="stream-unavailable"
                        type="text"
                        value="Security stream available from 0:00 a.m. – 2 p.m. and 4:00 p.m. – 12:00 p.m."
                        readOnly
                    />
                    :<HumanStream
                    alertSoundPermission={alertSoundPermission}
                    />
            }
        </section>
    )
}
function HumanStream({alertSoundPermission}){
    React.useEffect(
        () => {
            document.getElementById(
                "human-stream-video"
            ).onabort = ()=>console.log(
                "Video element aborted."
            );
            document.getElementById(
                "human-stream-video"
            ).oncanplay = ()=>console.log(
                "Video element can play."
            );
            document.getElementById(
                "human-stream-video"
            ).oncanplaythrough = ()=>{
                console.log(
                    "Video can play through."
                );
                document.getElementById(
                    "human-stream-video"
                ).playbackRate = 1.1;
            }
            document.getElementById(
                "human-stream-video"
            ).ondurationchange = ()=>console.log(
                "Video duration changed."
            );
            document.getElementById(
                "human-stream-video"
            ).onemptied = ()=>console.log(
                "Current playlist is empty"
            );
            document.getElementById(
                "human-stream-video"
            ).onended = ()=>console.log(
                "Current playlist has ended."
            );
            document.getElementById(
                "human-stream-video"
            ).onerror = ()=>console.log(
                "Video has error"
            );
            document.getElementById(
                "human-stream-video"
            ).onloadeddata = ()=>console.log(
                "Video has loaded data"
            );
            document.getElementById(
                "human-stream-video"
            ).onloadedmetadata = ()=>console.log(
                "Video has loaded metadata"
            );
            document.getElementById(
                "human-stream-video"
            ).onloadstart = ()=>console.log(
                "Browser starts looking for video src."
            );
            document.getElementById(
                "human-stream-video"
            ).onpause = ()=>console.log(
                "Video has been paused."
            );
            document.getElementById(
                "human-stream-video"
            ).onplay = ()=>console.log(
                "Video has resumed/started."
            );
            document.getElementById(
                "human-stream-video"
            ).onplaying = ()=>console.log(
                "Video has resumed."
            );
            document.getElementById(
                "human-stream-video"
            ).onprogress = ()=>{
                console.log(
                    "Video is being downloaded."
                );
                document.getElementById(
                    "human-stream-video"
                ).playbackRate = 1.1;
            };
            document.getElementById(
                "human-stream-video"
            ).onratechange = ()=>console.log(
                "Video playing speed has been changed."
            );
            document.getElementById(
                "human-stream-video"
            ).onseeked = ()=>console.log(
                "Video is playing from a new playback position."
            );
            document.getElementById(
                "human-stream-video"
            ).onseeking = ()=>console.log(
                "Video is moving to a new playback position."
            );
            document.getElementById(
                "human-stream-video"
            ).onstalled = ()=>console.log(
                "Video is being stalled."
            );
            document.getElementById(
                "human-stream-video"
            ).onsuspend = ()=>console.log(
                "Browser has stopped collecting frames."
            );
            // document.getElementById(
            //     "human-stream-video"
            // ).ontimeupdate = ()=>console.log(
            //     "Video playback position has changed"
            // );
            document.getElementById(
                "human-stream-video"
            ).onwaiting = ()=>console.log(
                "Video waiting for next frame."
            );
        }, []
    )
    return(
        <div id="screen">
            <div id={alertSoundPermission?"sound-on-icon":"sound-off-icon"}>
                {
                    alertSoundPermission
                        ?<MdVolumeUp/>
                        :<MdVolumeOff/>
                }
            </div>
            <video
            id="human-stream-video"
            autoPlay
            crossorigin="use-credentials"
            muted
            playsinline
            
            >
                <p>系統維護中, 請耐心等候。不便之處，敬請見諒。</p>
            </video>
            {/* Use wav file */}
            <audio
            loop
            preload="auto"
            id="alert-audio"
            autoplay
            src={alertAudio}
            />
        </div>
    )
}
